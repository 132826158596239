<template>
  <b-card-code title="Set camera movement for current Project">
    <div>
        <b-form class="position-relative" @submit.prevent="onSubmit">
            <b-form-radio-group
                label="Camera movements"
                label-cols-lg="2"
                v-model="selectedMovement"
                class="d-flex align-items-center"
                style="gap: 30px;"
            >
                <b-form-radio :disabled="!hasLicensePermission('camera-movement', 'unlimited_movement')" value="unlimited">Unlimited movement</b-form-radio>
                <a
                    v-if="!hasLicensePermission('camera-movement', 'unlimited_movement')"
                    href="/pricing"
                    target="_blank"
                    title="Unlimited movement for higher license"
                >
                    <feather-icon
                        class="cursor-pointer"
                        icon="AlertTriangleIcon"
                        size="20"
                        style="color: #F00; margin-left: -20px;"
                    />
                </a>
                
                <b-form-radio :disabled="!hasLicensePermission('camera-movement', 'vertical_rotation')" value="vertical-rotation">Vertical rotation</b-form-radio>
                <a
                    v-if="!hasLicensePermission('camera-movement', 'vertical_rotation')"
                    href="/pricing"
                    target="_blank"
                    title="Vertical rotation for higher license"
                >
                    <feather-icon
                        class="cursor-pointer"
                        icon="AlertTriangleIcon"
                        size="20"
                        style="color: #F00; margin-left: -20px;"
                    />
                </a>

                <b-form-radio :disabled="!hasLicensePermission('camera-movement', 'horizontal_rotation')" value="horizontal-rotation">Horizontal rotation</b-form-radio>
                <a
                    v-if="!hasLicensePermission('camera-movement', 'horizontal_rotation')"
                    href="/pricing"
                    target="_blank"
                    title="Horizontal rotation for higher license"
                >
                    <feather-icon
                        class="cursor-pointer"
                        icon="AlertTriangleIcon"
                        size="20"
                        style="color: #F00; margin-left: -20px;"
                    />
                </a>
            </b-form-radio-group>

            <div class="d-flex justify-content-center">
                <b-button
                    ref="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="busyButton"
                >
                    Submit
                </b-button>
            </div>

            <b-overlay :show="busy" no-wrap @shown="onShown" @hidden="onHidden">
                <template #overlay>
                    <div
                    v-if="processing"
                    class="text-center p-3 bg-primary text-light rounded"
                    >
                    <feather-icon icon="UploadCloudIcon" size="20" />
                    <div class="mb-2">Preservation...</div>
                    <b-progress
                        min="1"
                        max="5"
                        :value="counter"
                        variant="success"
                        height="6px"
                        class="mx-n3"
                    />
                    </div>
                    <div
                        v-else
                        ref="dialog"
                        tabindex="-1"
                        role="dialog"
                        aria-modal="false"
                        aria-labelledby="form-confirm-label"
                        class="text-center p-3"
                    >
                        <b-card-text class="font-weight-bolder">
                            Are you sure?
                        </b-card-text>
                        <div class="d-flex">
                            <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mr-3"
                                @click="onCancel"
                            >
                                Cancel
                            </b-button>
                            <b-button
                                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                variant="outline-success"
                                @click="onOK"
                            >
                                OK
                            </b-button>
                        </div>
                    </div>
                </template>
            </b-overlay>
        </b-form>
    </div>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code"
import { 
    BButton,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BCardText,
    BFormCheckbox,
}
from "bootstrap-vue"
import { ref } from "@vue/composition-api"
import store from "@/store"
import Ripple from "vue-ripple-directive";
import { errorMessage } from "@/auth/utils"
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BButton,
        BOverlay,
        BForm,
        BProgress,
        BFormGroup,
        BInputGroup,
        BCardCode,
        BFormInput,
        BCardText,
        BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    computed: {
        settings() {
            return this.$store.state.Editor.cameraSetting
        },
        editor() {
            return this.$store.state.Editor.editor
        },
        licensePermissions() {
            return this.$store.getters["app/LICENSE_PERMISSIONS"]
        },
    },
    data() {
        return {
            busy: false,
            busyButton: true,
            processing: false,
            counter: 1,
            interval: null,
            editorIsLoaded: false,
            selectedMovement: 'unlimited',
            movementOptions: [
                { text: 'Unlimited movement', value: 'unlimited' },
                { text: 'Vertical rotation', value: 'vertical-rotation' },
                { text: 'Horizontal rotation', value: 'horizontal-rotation' },
            ]
        }
    },
    methods: {
        hasLicensePermission(permission_type, permission_field) {
            // Look in license_permission.json to manage permission_type and permission_field
            try {
                return this.isLicenseChosen(this.licensePermissions[permission_type][permission_field].split(" "))
            } catch {
                return true
            }
        },
        isLicenseChosen(licenseList) {
            for (let license of licenseList) {
                if (license == this.projectData.project_license_type) {
                    return true
                }
            }
            return false
        },
        onSubmit() {
            if (this.editorIsLoaded) {
                this.processing = false
                this.busy = true
                this.busyButton = true
            }
        },
        onCancel() {
            this.busy = false
            this.busyButton = false
        },
        updateProcessing() {
            // Counter is going up from 1 to 5
            // Simulate an async request
            this.clearInterval();
            this.interval = setInterval(() => {
                if (this.counter < 5) {
                this.counter += 1
                } else {
                this.clearInterval()
                this.$nextTick(() => {
                    // eslint-disable-next-line
                    this.busy = this.processing = false
                });
                }
            }, 350);
        },
        async onOK() {
            this.processing = true
            this.$store.commit('app/SET_IS_PROJECT_UPDATING', true)
            this.counter = 1
            const bgSettings = this.settings

            bgSettings.cameraMovement = this.selectedMovement
            
            await this.setEffectsSettings(bgSettings)
            this.updateProcessing()
            this.$store.commit('app/SET_IS_PROJECT_UPDATING', false)
            await this.loadEditorAsync()
        },
        async setEffectsSettings(bgSettings) {
            // PATCH Editor with new settings_json file
            await this.$store.commit("Editor/SET_CAMERA", bgSettings)
            await this.$store
                .dispatch("Editor/changeSetting", this.$store.state.Editor.editor.id)
                .then(() => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: "You have successfully updated Project effects settings!",
                            icon: "EditIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((error) => {
                    errorMessage(this.$toast, error)
                })
        },
        async loadEditorAsync() {
            const _this = this
            // Set headers for fetch
            let myHeaders = new Headers()
            myHeaders.append('pragma', 'no-cache')
            myHeaders.append('cache-control', 'no-cache')

            // Set request options for fetch
            const myInit = {
                method: 'GET',
                headers: myHeaders,
            };

            await this.$store
                .dispatch("Editor/getEditor", this.$store.state.Editor.editor.id)
                .then((res) => {
                this.settings_json_s3_path = res.data.settings_json_s3_path
                
                this.$store.commit("Editor/SET_EDITOR", res.data)
                this.$store
                .dispatch("Editor/setCameraSetting") // Fetch settings json file
                .then((response) => response.json())
                .then((data) => {
                    this.$store.commit("Editor/SET_CAMERA", data)
                });
            })
                .catch((error) => {
                errorMessage(this.$toast, error)
                });

            // Get data by settings_json_s3_path PATH and myInit request options
            await fetch(this.settings_json_s3_path, myInit)
                .then(function (response) {
                return response.json() // Get json data first
                }).then(function(response) {
                if (response.effects) {
                    _this.selectedMovement = response.cameraMovement? response.cameraMovement: 'unlimited'
                }
                _this.editorIsLoaded = true
                _this.busyButton = false
            })
        },
        clearInterval() {
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }
        },
    },
    setup() {
        const projectData = ref(null)

        store
        .dispatch("Project/loadProject", store.state.Project.project.id)
        .then((response) => {
            projectData.value = response.data;
        })
        .catch((error) => {
            if (error.response.status === 404) {
            projectData.value = undefined;
            }
        });

        return {
            projectData,
        };
    },
    mounted() {
        this.loadEditorAsync()
    },
    beforeDestroy() {
        this.clearInterval();
    },
}
</script>

<style lang="scss">

</style>